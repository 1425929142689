@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&family=Roboto+Mono:wght@400&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --background: 220 16% 4%;
        --foreground: 240 9% 98%;

        --border: 240, 6%, 59%;
        --input: 240, 6%, 59%;
        --ring: 240 4.9% 83.9%;

        --orange: hsla(19, 100%, 51%, 1);
        --light-orange: hsla(25, 100%, 59%, 1);
        --lighter-orange: hsla(32, 100%, 64%, 1);
        --dark-orange: hsla(20, 100%, 56%);

        --blue: hsla(240, 95%, 68%, 1);
        --light-blue: hsla(239, 100%, 81%);

        --grey: hsla(240, 6%, 59%, 1);

        --dark-contrast: hsla(216, 10%, 10%, 1);
        --medium-dark-contrast: hsla(210, 7%, 17%, 1);
        --light-dark-contrast: hsla(225, 7%, 23%, 1);
        --core-dark-contrast: hsla(200, 6%, 29%, 1);

        --contrast-1: hsla(216, 10%, 10%, 1);
        --contrast-2: hsla(210, 7%, 17%, 1);
        --contrast-3: hsla(225, 7%, 23%, 1);
        --contrast-4: hsla(233, 5%, 34%, 1);

        --white: hsla(0, 0%, 100%, 1);
        --black: black;
        --grey-10: hsl(240, 5%, 96%);
        --grey-20: hsla(240, 5%, 92%, 1);
        --grey-40: hsla(240, 6%, 84%, 1);
        --grey-60: hsla(240, 6%, 75%, 1);
        --grey-80: hsla(240, 5%, 67%, 1);
        --grey-highlight: hsla(240, 12%, 22%, 1);

        scroll-behavior: smooth;
    }
}

@layer base {
    body {
        @apply bg-black text-white;
    }
}

html,
body {
    font-family: 'Manrope', sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    scroll-behavior: smooth;
}

/* gallery counter */
.pswp__counter {
    font-family: sans-serif !important;
}

.bg-custom-gradient {
    background: linear-gradient(90deg, #ff9c23 16.58%, #9d9fff 69.45%);
}

.gradient-text {
    background: linear-gradient(90deg, #ff8630 16.58%, #6162fb 69.45%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hover\:gradient-text:hover {
    -webkit-text-fill-color: transparent;
    width: fit-content;
    animation: gradientAnimation 0.3s forwards;
}

@keyframes gradientAnimation {
    from {
        background: currentColor;
        background-clip: text;
    }

    to {
        background: linear-gradient(90deg, #ff8630 16.58%, #6162fb 69.45%);
        background-clip: text;
    }
}

/* rainbow text */
.rainbow-text {
    text-align: center;
    text-decoration: underline;
    font-size: 32px;
    font-weight: 400;
}

.rainbow-text {
    background: linear-gradient(to right,
            #6666ff,
            #0099ff,
            #00ff00,
            #ff3399,
            #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbowAnimation 6s ease-in-out infinite;
    background-size: 400% 100%;
}

@keyframes rainbowAnimation {

    0%,
    100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}