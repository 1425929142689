.hero-video {
  width: 100%;
  object-fit: cover;
}

.observe-sticky-target.is-pinned {
  transform: translateY(100px);
}

.hero {
  height: calc(100dvh - 84px);
}

@media (max-width: 520px) {
  .hero {
    height: calc(100dvh - 84px);
  }
}

@media (max-width: 1024px) {
  .hero {
    height: calc(100dvh - 74px);
  }
}
